(() => {

	const faixaTopo = document.querySelector('.topo__faixa');
	const topo      = document.querySelector('.topo');
	const classe    = 'topo--fixed';

	checker();

	function checker() {
		// Adiciona no topo a variavel css com o height da .faixa__topo
		topo.style.setProperty('--offsetXL', -faixaTopo.offsetHeight+'px');

		// Verificando se a posição do scroll da página é igual ou maior que o height data .faixa__topo
		if(window.scrollY >= faixaTopo.offsetHeight) {
			topo.classList.add(classe);
		}else {
			topo.classList.remove(classe);
		}

		// Dispara e função que checa, a cada atualização de frame
		requestAnimationFrame(checker);
	}
})();
